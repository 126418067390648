import { Language } from "types/common/global";

export const languages: readonly Language[] = [
	{
		value: "en",
		label: "English",
	},
	{
		value: "de",
		label: "Deutsch",
	},
	// {
	// 	value: "el",
	// 	label: "Eλληνικά",
	// },
	{
		value: "it",
		label: "Italiano",
	},
	{
		value: "es",
		label: "Español",
	},
	{
		value: "fr",
		label: "Français",
	},
	{
		value: "pt",
		label: "Português",
	},
	{
		value: "sv",
		label: "Svenska",
	},
	{
		value: "pl",
		label: "Polski",
	},
	{
		value: "zh",
		label: "中文 (简体)",
	},
	{
		value: "ja",
		label: "日本語",
	},
	{
		value: "ko",
		label: "한국어",
	},
	{
		value: "th",
		label: "ภาษาไทย",
	},
	{
		value: "nl",
		label: "Nederlands",
	},
	{
		value: "id",
		label: "Bahasa Indonesia",
	},
	{
		value: "pt-BR",
		label: "Português (Brasil)",
	},
	{
		value: "tr",
		label: "Türkçe",
	},
	{
		value: "ru",
		label: "Русский язык",
	},
];
